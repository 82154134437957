// table.scss
.table {
    thead {
        tr {
            th {
                font-size: $font-size-base;
                line-height: $line-height-xs;
                color: $dark;
            }
        }
    }
    tbody {
        tr {
            td {
                // padding: $table-data-padding-y $table-data-padding-x;
                vertical-align: middle;
                font-size: $font-size-md;
            }
        }
    }
    &.table-nowrap {
        th, td {
            white-space: nowrap;
        }
    }
    &.table-sm {
        tbody {
            tr {
                td {
                    padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
                }
            }
            
        }
    }
}