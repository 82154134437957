// badges.scss

.badge {
    line-height: 16px;
}

@each $color, $value in $theme-colors {
    .badge {
        &.badge-outline-#{$color} {
            border: 1px solid $value !important;
            color: $value !important;
            background-color: transparent !important;
        }
        &.badge-soft-#{$color} {
            background-color: rgba($value, 0.16) !important;
            border: 1px solid rgba($value, 0.16);
            color: $value;
        }
    }
}