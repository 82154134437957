// buttons.scss
.btn {
    &.btn-sm {
        line-height: 18px;
    }
    &.btn-md {
        padding: 7px 10px;
        font-size: 15px;
        line-height: 18px;
    }
    .btn-icon {
        height: 20px;
        width: 20px;
    }
    &.btn-export {
        &>span {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
}
@each $color, $value in $theme-colors {
    .btn {
        &.btn-#{$color} {
            box-shadow: 0px 4px 6px rgba(shade-color($value, 60%) , .20);
        }
        &.btn-soft-#{$color} {
            background-color: rgba($value, 0.15);
            border-color: rgba($value, 0.5);
            color: $value;
            &:hover,
            &.active,
            &:active,
            &.focus,
            &:focus-visible {
                background-color: rgba($value, 0.25) !important;
                color: $value !important;
                border-color: gba($value, 0.8) !important;
            }
        }
    }
    button{
        &.btn-soft-#{$color} {
            box-shadow: none !important;
        }
    }
}

.btn-light {
    box-shadow: none !important;
    color: $dark !important;
}


// table-btn
.btn {
    &.table-btn {
        height: 26px;
        width: 26px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-sm;
        i {
            position: relative;
            top: 1.5px;
        }
    }
}
