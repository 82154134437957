// form.scss
.form-check-label {
    font-size: $font-size-sm;
}

.custom-form-lg {
    .form-label {
        font-size: $font-size-base + 0.125;
    }
    .form-control {
        padding: $input-padding-y-lg $input-padding-x-lg;
        font-size: $input-font-size-lg;
    }
}

label.form-check-label {
    font-size: $font-size-base;
}
.form-select-md {
    padding: 0.45rem 2.8rem 0.45rem 0.8rem;
    font-size: .914rem;
}

// input-icon-content
.input-icon-content {
    position:relative;
    .input-icon {
        position: absolute;
        top: 14px;
        right:16px;
        color: $form-label-color;
    }
}
.form-space {
    margin-bottom: $spacer;
}
form.was-validated {
    .input-icon-content {
        .input-icon {
            right:36px;
        }
    }
}

.input-box {
    padding: $input-padding-y $input-padding-x;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    border-radius: var(--bs-border-radius-sm);
}

// Responsive
@include media-breakpoint-down(md) {
    .form-label {
        margin-bottom: 0.313rem;
    }
    .form-control,
    .input-box,
    .form-select {
        padding: $input-padding-y;
    }
    .form-space {
        margin-bottom: 12px;
    }
}