// top-menu.scss
.navbar {
    &.top-menu {
        position: relative;
        background-color: $white;
        z-index: 1;
        border-bottom: $border-width solid $gray-400;
        box-shadow: 0px 4px 6px rgba($dark, 0.08);
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 16px 0;
                margin: 0 20px;
                font-size: 20px;
                font-weight: 500;
                position: relative;
                &::after {
                    content: "";
                    background-color: $primary;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0px;
                    height: 2px;
                    width: 0%;
                    margin: 0 auto;
                    opacity: 0;
                    transition: $transition-base;
                }
                &.active, &:hover {
                    color: $primary;
                    &::after {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
        }
        .topbar-user {
            .dropdown-toggle {
                border: none;
                padding: 12px 0;
                &:hover {
                    color: $primary;
                }
            }
        }
        .navbar-toggler {
            border: none;
            padding: 0;
            box-shadow: none;
        }
    }
}


// Responsive
@include media-breakpoint-down(lg) {
    .navbar {
        &.top-menu {
            padding: 2px 0;
            .navbar-nav {
                margin-bottom: 10px;
                .nav-link {
                    margin: 0;
                    padding: 10px 0;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .top-menu {
        .leftbar-logo {
            a>img {
                height: 26px;
            }
        }
        .topbar-user {
            .dropdown-toggle {
                padding: 12px 0 !important;
            }
        }
    }
}