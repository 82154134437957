// react-select.scss
.custom-form-input {
    // custom-react-select
    .css-13cymwt-control,
    .css-t3ipsp-control,
    .css-16xfy0z-control {
        .css-1fdsijx-ValueContainer,
        .css-3w2yfm-ValueContainer,
        .css-3w2yfm-ValueContainer {
            padding: 12px 14px 6px;
            .css-1p3m7a8-multiValue {
                border-radius: $input-border-radius;
                background-color: rgba($primary, .16);
                border: 1px solid rgba($primary, .16);
                margin-left: 0;
                margin-right: 5px;
                .css-wsp0cs-MultiValueGeneric {
                    font-size: 14px;
                    line-height: 14px;
                    padding: 4px 8px;
                    border-radius: 0;
                }
            }
        }
        .css-1jqq78o-placeholder {
            color: $input-placeholder-color;
        }
        .css-wsp0cs-MultiValueGeneric {
            background-color: transparent;
            color: $primary;
        }
        .css-12a83d4-MultiValueRemove {
            color: $primary;
            background-color: rgba($primary, .16);
            border-radius: 0 3px 3px 0;
            &>svg {
                height: 18px;
                width: 18px;
            }
        }
    }
    .css-13cymwt-control,
    .css-16xfy0z-control {
        border-color: $input-border-color;
        background-color: $input-bg;
    }
    .css-t3ipsp-control,
    .css-t3ipsp-control:hover,
    .css-16xfy0z-control,
    .css-16xfy0z-control:hover {
        border-color: $input-focus-border-color;
        box-shadow: none;
    }
    .css-16xfy0z-control[aria-disabled="true"] {
        background-color: $input-disabled-bg;
    }
    .css-13cymwt-control {
        &:hover {
            border-color: $input-border-color;
        }
    }
    .css-1nmdiq5-menu {
        .css-1n6sfyn-MenuList {
            &>div {
                color: $form-select-color;
                &:hover, &:first-child {
                    background-color: rgba($primary, .18);
                    color: $primary;
                }
            }
        }
    }
    .css-b62m3t-container,
    .css-3iigni-container {
        .css-13cymwt-control,
        .css-t3ipsp-control,
        .css-16xfy0z-control {
            .css-3w2yfm-ValueContainer,
            .css-1fdsijx-ValueContainer,
            .css-3w2yfm-ValueContainer {
                padding: 6px 14px;
                &>div {
                    line-height: $input-line-height;
                }
            }
        }
    }
    &.custom-form-input-sm {
        .css-13cymwt-control,
        .css-t3ipsp-control {
            min-height: 34px !important;
            .css-1fdsijx-ValueContainer,
            .css-3w2yfm-ValueContainer {
                padding: 8px 0px 2px 14px;
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .custom-form-input .css-b62m3t-container .css-13cymwt-control .css-3w2yfm-ValueContainer, 
    .custom-form-input .css-b62m3t-container .css-13cymwt-control .css-1fdsijx-ValueContainer, 
    .custom-form-input .css-b62m3t-container .css-t3ipsp-control .css-3w2yfm-ValueContainer, 
    .custom-form-input .css-b62m3t-container .css-t3ipsp-control .css-1fdsijx-ValueContainer {
        padding: 9px 12px;
    }
}