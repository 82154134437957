// datatable.scss

.dataTables_wrapper {
    .dataTables_length,
    .dataTables_filter,
    .dt-buttons {
        margin-bottom: calc($grid-gutter-width / 2);
    }
    .dataTables_filter {
        label>input,
        label>input::placeholder {
            font-size: 16px;
        }
    }
    table.dataTable>thead>tr>th, 
    table.dataTable>thead>tr>td {
        border-bottom: none;
        padding: 8px 10px;
    }
    table.dataTable>thead>tr>th {
        &.sorting {
            padding: 8px 24px 8px 10px;
        }
    }
    table.dataTable thead th, 
    table.dataTable tfoot th {
        font-weight: $font-weight-medium;
    }
    table.dataTable tbody th, 
    table.dataTable tbody td {
        padding: 5px 10px;
    }
    .dt-buttons {
        float: left;
        margin-right: 10px;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
    }
    &.dtl-right {
        .dataTables_length {
            float: right;
            margin-right: 12px;
        }
    }
    .dataTables_length {
        label {
            font-size: 18px;
            color: $text-muted;
        }
        select {
            border: $form-select-border-width solid $form-select-border-color;
            color: $input-color;
            border-radius: $input-border-radius;
            margin: 0 4px;
            &:focus-visible {
                outline: none;
            }
        }
    }
    .dataTables_filter {
        input[type="search"] {
            border: $form-select-border-width solid $form-select-border-color;
            color: $input-color;
            border-radius: $input-border-radius;
            font-size: $font-size-base;
            margin-left: 4px;
            padding: 6px 14px;
            outline-offset: 0;
            &:focus-visible {
                border-color: $input-focus-border-color; 
                outline: none;
            }
            &::placeholder {
                font-size: $font-size-base;
                color: $input-placeholder-color;
            }
        }
    }
    table.dataTable {
        border-collapse: collapse;
        border-bottom:$form-select-border-width solid $table-border-color;

        thead>tr>th.sorting:before, 
        thead>tr>th.sorting:after, 
        thead>tr>th.sorting_asc:before, 
        thead>tr>th.sorting_asc:after, 
        thead>tr>th.sorting_desc:before, 
        thead>tr>th.sorting_desc:after, 
        thead>tr>th.sorting_asc_disabled:before, 
        thead>tr>th.sorting_asc_disabled:after, 
        thead>tr>th.sorting_desc_disabled:before, 
        thead>tr>th.sorting_desc_disabled:after, 
        thead>tr>td.sorting:before, 
        thead>tr>td.sorting:after, 
        thead>tr>td.sorting_asc:before, 
        thead>tr>td.sorting_asc:after, 
        thead>tr>td.sorting_desc:before, 
        thead>tr>td.sorting_desc:after, 
        thead>tr>td.sorting_asc_disabled:before, 
        thead>tr>td.sorting_asc_disabled:after, 
        thead>tr>td.sorting_desc_disabled:before, 
        thead>tr>td.sorting_desc_disabled:after {
            line-height: 9px;
            font-size: .6em;
        }
    }
    .dataTables_paginate,
    .dataTables_paginate span {
        display: flex;
        align-items: center;
    }
    .dataTables_info {
        padding-top: 16px;
        color: $text-muted;
    }
    .dataTables_paginate {
        padding-top: calc($grid-gutter-width / 2);
        .paginate_button  {
            background-color: $light;
            height: 28px;
            width: 100%;
            font-size: 14px;
            color: $dark !important;
            padding: 6px 9.5px;
            height: 28px;
            min-width: 28px !important;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 3px;
            border-radius: $pagination-border-radius;
            &.next {
                margin-right: 0;
            }
            &.current, &:hover, &:focus, &:active, &.current:hover, &.current:focus {
                background: $primary !important;
                color: $white !important;
                border-color: $primary !important;
                box-shadow: none;
            }
            &.disabled {
                &:hover {
                    background: transparent !important;
                    color: $dark !important;
                    border-color: transparent !important;
                }
            }
        }
    }
    // .dataTables_empty {
    //     display: none;
    // }
    .date-filter {
        display: flex;
        gap: 10px 16px;
        margin-bottom: 12px;
        flex-wrap: wrap;
        justify-content: end;
        .from-date, .to-date {
            label {
                font-size: 18px;
                color: #59637D;
                display: flex;
                flex-wrap: wrap;
                gap: 6px;
                align-items: center;
                input.datepicker {
                    border: $form-select-border-width solid $form-select-border-color;
                    color: $input-color;
                    border-radius: $input-border-radius;
                    font-size: $font-size-base;
                    padding: 6px 30px 6px 12px;
                    outline-offset: 0;
                    max-width: 200px;
                    width: 100%;
                    &:focus-visible {
                        border-color: $input-focus-border-color; 
                        outline: none;
                    }
                    &::placeholder {
                        font-size: $font-size-base;
                        color: $input-placeholder-color;
                    }
                }
            }
        }
        .date-input-with-icon {
            position: relative;
            .date-input-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}

// ui-datepicker
.ui-datepicker.ui-widget {
    border: $card-border-width solid $card-border;
    box-shadow: $box-shadow-lg;
    border-radius: $card-border-radius;
    padding: .5em;
    font-family: $font-family-base;
    color: $dark;
    max-width: 20em;
    margin-top: 2px;
    input, select, textarea, button {
        font-family: $font-family-base;
    }
    .ui-datepicker-header {
        background-color: $gray-200;
        border-color: $card-border;
        display: flex;
        align-items: center;
        justify-content: center;
        .ui-datepicker-prev,
        .ui-datepicker-next  {
            background-color: transparent !important;
            border: none !important;
            color: $dark !important;
            width: 20px;
            height: 20px;
            top: auto !important;
        }
        .ui-datepicker-prev {
            left: 2px;
        }
        .ui-datepicker-next {
            right: 2px;
        }
        .ui-datepicker-title {
            line-height: normal;
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            select {
                font-size: $font-size-sm;
                padding: 2px;
                border: $card-border-width solid $gray-400;
                border-radius: $border-radius-sm;
                color: $form-label-color;
                font-weight: $font-weight-bold;
                width: auto;
            }
        }
    }
    .ui-datepicker-calendar {
        margin-bottom: 0;
        tbody {
            td {
                padding: 2px;
                .ui-state-default {
                    border: $border-width solid shade-color($gray-200, 5);
                    background: transparent;
                    border-radius: $border-radius-sm;
                    color: $text-muted;
                    text-align: center;
                    font-size: $font-size-sm;
                }
                &.ui-datepicker-today {
                    .ui-state-default {
                        background-color: $primary;
                        border-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .dataTables_wrapper {
        &.dtl-right {
            .dt-buttons {
                float: none;
                justify-content: center;
                margin-bottom: $grid-gutter-width;
            }
            .dataTables_length {
                float: none;
            }
        }
        .dataTables_length {
            display: none;
        }
        .dataTables_filter {
            margin-top: 0;
        }
        .dataTables_paginate {
            justify-content: center;
        }
    }
}

