// utilities.scss

// font-size, icon-size
@for $i from 10 through 100 {
    .fs-#{$i} {font-size: 1px * $i; }
    .icon-size-#{$i} {
        min-height: 1px * $i; 
        min-width: 1px * $i; 
        max-height: 1px * $i; 
        max-width: 1px * $i; 
    }
}

// margin-bottom
@for $i from 1 through 100 {
    .c-mt-#{$i} { 
        margin-top: 1px * $i;
    }
    .c-me-#{$i} { 
        margin-right: 1px * $i;
    }
    .c-mb-#{$i} { 
        margin-bottom: 1px * $i;
    }
    .c-ms-#{$i} { 
        margin-left: 1px * $i;
    }
}