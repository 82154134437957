// pagetile.scss
.page-title-content {
    padding: 10px 0;
    .page-title {
        color: $gray-800;
        font-size: $page-title-size;
        margin-bottom: 0;
    }
    .breadcrumb {
        margin-bottom: 0;
        .breadcrumb-item {
            a {
                color: $text-muted;
                cursor: default;
            }
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: 400 !important;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1.7;
        vertical-align: middle;
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
    }
}

// responsive
@include media-breakpoint-down(sm) {
    .page-title-content {
        .page-title {
            font-size: $page-title-size-sm;
        }
        .breadcrumb {
            .breadcrumb-item {
                    font-size: $font-size-sm;
            }
        }
    }
}
@include media-breakpoint-down(xl) {
    .page-title-content {
        .page-title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    } 
}
