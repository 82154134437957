// navtabs.scss

.custom-navtab {
    .nav-item {
        // margin-right: 10px;
        // &:last-child {
        //     margin-right: 0;
        // }
        .nav-link {
            background-color: $light;
            border: 1px solid shade-color($light, 5);
            color: $dark;
            line-height: $line-height-sm;
            white-space: nowrap;
            &.active {
                background-color: $primary;
                color: $white;
                border-color: $primary;
            }
            
        }
    }
}