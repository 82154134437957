.Toastify__toast-theme--colored.Toastify__toast--error{
    background-color: $danger;
}

.Toastify__toast-theme--colored.Toastify__toast--success{
    background-color: $success;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: $warning;
}