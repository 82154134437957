// card.scss

.card {
    border-color: $card-border;
    box-shadow: $card-box-shadow;
    margin-bottom: $grid-gutter-width;
    .card-title {
        font-size: $card-title;
        font-weight: $font-weight-bold;
        margin-bottom: $card-title-mb;
    }
    .card-text {
        color: $gray-700;
    }
}

@include media-breakpoint-down(md) {
    .card {
        .card-body {
            padding: $card-spacer-y / 2;
            .card-title {
                margin-bottom: $card-spacer-y / 2;
            }
        }
    }
}
