// avatar.scss

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
}
.avatar-xxxl {
    height: 100px;
    width: 100px;
    font-size: 50px;
}
.avatar-xxl {
    height: 75px;
    width: 75px;
    .avatar-icon {
        font-size: 40px;
    }
}
.avatar-xl {
    height: 60px;
    width: 60px;
    min-height: 60px;
    min-width: 60px;
    font-size: 36px;
}
.avatar-lg {
    height: 55px;
    width: 55px;
    .avatar-icon {
       font-size: 25px;
    }
}
.avatar-md {
    height: 42px;
    width: 42px;
    .avatar-icon {
       font-size: 24px;
    }
}
.avatar-sm {
    height: 32px;
    width: 32px;
    border-radius: $border-radius-sm;
    .avatar-icon {
        font-size: 18px;
    }
}
.avatar-xs {
    height: 24px;
    width: 24px;
    border-radius: $border-radius-sm;
    .avatar-icon {
        font-size: 11px;
    }
}