// dashboard.scss
.widgets-card {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    .avatar {
        border-radius: $border-radius-sm;
        border: $border-width solid;
    }
}

@each $color, $value in $theme-colors {
    .widgets-#{$color} {
        .avatar {
            background-color: rgba($value, 0.16);
            border-color: rgba($value, 0.16);
            color: $value;
        }
        .widgets-title {
            color: $value;
        }
        &.widgets-active {
            border-color: $value;
            .avatar {
                background-color: $value;
                border: $value;
                color: $white;
            }
        }
    }
}

