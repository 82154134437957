//modal.scss

.modal {
    backdrop-filter: blur(10px);
    .modal-header {
        border-bottom: none;
        padding-bottom:0px !important;
        .modal-title {
            font-size: 24px;
            font-weight: $font-weight-semibold;
        }
        .btn-close {
            height: 30px;
            width: 30px;
            background-color: $light;
            padding: 0px;
            border-radius: $border-radius-sm;
            margin: 0;
        }
    }
    .modal-footer {
        border-top: none;
        padding-top: 0;
    }
}

@include media-breakpoint-down(md) {
    .modal {
        .modal-content {
            .modal-header, .modal-body {
                padding: $modal-inner-padding / 2;
            }
        }
    }
}