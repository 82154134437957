// helper.scss

.text-muted {
    color: $text-muted !important;
}

.auth-img{
    border-radius: 50%;
    border:2px solid white;
    width: 26px;
}

.add-btn{
    position: absolute;
    right: 10px;
    bottom: 0;
}

.pointer-cursor {
    cursor: pointer;
    .form-check-input , .form-check-label {
        cursor: pointer;
    }
}

.img-shadow {
    box-shadow: 0px 4px 10px rgba($dark, 0.2);
}

.png-img-shadow {
    filter: drop-shadow(0px 4px 4px rgba($dark, 0.1))
}
