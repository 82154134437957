// footer.scss
.footer {
    background-color: $gray-200;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    .footer-content {
        height: $footer-height;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}