// auth.scss

.auth-bg{
    display: flex;
    min-height: 100vh;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: url('../../images/auth-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    .img-shadow {
        box-shadow: $img-shadow;
    }
}

.auth-or-border {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 42%;
      height: 1px;
      background-color: $gray-300;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 42%;
      height: 1px;
      background-color: $gray-300;
    }
  }

  //otp
  
  .otp-inputs .form-control {
    width: 60px;
  }
  
  .otp-inputs .form-control:last-child {
    margin-right: 0;
  }


//  auth-logo
.auth-logo {
    background-color: $primary;
    height: 108px;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: -50px auto;
    border: 4px solid #ffffff;
    box-shadow: 0px 0px 0px 1px $primary;
}